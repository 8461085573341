import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import { fluid, MaxWidthContainer } from '../styles/mixins'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import ContentBlock from './modules/ContentBlock'

function BlogPost (props) {
  const { _rawBody, _rawIntro, title, mainImage, publishedAt } = props
  return (
    <Article>
			<MaxWidthContainer>
			<div className="top">
				{mainImage && mainImage.asset && (
					<div className="left">
						<img
							src={imageUrlFor(buildImageObj(mainImage))
								.width(400)
								.height(Math.floor((16 / 12) * 400))
								.fit('crop')
								.url()}
							alt={mainImage.alt}
						/>
					</div>
				)}
				<div className="right">
					<h2 className="blog-title">{title}</h2>
					{_rawIntro && <ContentBlock content={_rawIntro} />}
				</div>
				
			</div>
			<div className="bottom">
				{_rawBody && <ContentBlock content={_rawBody} />}
				<aside>
					{publishedAt && (
						<div >
							{differenceInDays(new Date(publishedAt), new Date()) > 3
								? distanceInWords(new Date(publishedAt), new Date())
								: format(new Date(publishedAt), 'MMMM Do YYYY')}
						</div>
					)}
				</aside>
				</div>
			</MaxWidthContainer>
    </Article>
  )
}

export default BlogPost

const Article = styled.article`
	min-height:calc(100vh - 240px);
	display:flex;
	flex-direction:column;
	justify-content:center;
	.top {
		display:flex;
		align-items:center;
		color:${theme.colors.green};
		margin-top:50px;
		.left {
			margin-left:50px;
			margin-right:50px;
			img {
				margin-bottom:20px;
			}
		}
		.blog-title {
			font-family:${theme.fonts.bold};
					text-transform:uppercase;
					${fluid('font-size', 26, 32, 540, 800)}
					margin-bottom:20px;
		}
		
	}
	.bottom {
		margin-bottom:50px;
		p {
			margin-bottom:6px;
			margin-top:0;
			text-indent: 50px;
		}
	}
	aside {
		width:100%;
		text-align:right;
	}
	@media (${theme.breakpoints.small}) {
		.top {
			flex-direction:column;
			.left {
				width:100%;
				margin-bottom:10px;
				img {
					width:100%;
				}
				margin-left:0;
				margin-right:0;
				
			}
			.right {
				margin:0;
			}
		}
	}
`